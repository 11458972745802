import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { TypedStorage } from './utils/TypedStorage';

import type { ReactNode } from 'react';

export const AccessTokenSaver__DEPRECATED = ({ children }: { children: ReactNode }) => {
  const params = useSearchParams();

  useEffect(() => {
    const token = params?.get('authToken');
    if (!token) {
      return;
    }

    TypedStorage.set({ key: 'viewer_jwt', value: token });
  }, [params]);

  return <>{children}</>;
};
