import UAParser from 'ua-parser-js';

type UAParserSupportedBrowserName = 'firefox' | 'safari' | 'chrome' | 'edge';

const mapPermissionNameToMediaDeviceName = (permissionStatus: PermissionName | 'camera' | 'microphone'): string => {
  if (permissionStatus === 'microphone') {
    return 'audio';
  }
  if (permissionStatus === 'camera') {
    return 'video';
  }
  return permissionStatus;
};

export const userAgentInfo = (() => {
  if (typeof window === 'undefined') {
    return null;
  }
  const { userAgent } = window.navigator;
  if (!userAgent) {
    return null;
  }
  const parserResults = new UAParser(userAgent);
  return parserResults.getResult();
})();

export const isBrowser = (browser: UAParserSupportedBrowserName): boolean => {
  if (!userAgentInfo) {
    return false;
  }

  return userAgentInfo.browser.name?.toLowerCase() === browser;
};

// device.type is empty string if its on desktop
export const isDesktop = (() => {
  if (!userAgentInfo) {
    return false;
  }
  return !userAgentInfo.device.type;
})();

// Some browser doesn't support Permissions API (e.g. safari version 15.6 and before)
export const isPermissionsApiAvailable = (() => {
  if (!userAgentInfo) {
    return false;
  }
  if (isBrowser('safari')) {
    const versionNumber = parseInt(userAgentInfo.browser.version || '', 10);
    if (!versionNumber || versionNumber < 16) {
      return false;
    }
  }
  return true;
})();

export async function getHardwarePermissionStatus(name: PermissionName): Promise<Pick<PermissionStatus, 'state'>> {
  if (!isPermissionsApiAvailable) {
    try {
      await navigator.mediaDevices.getUserMedia({
        [mapPermissionNameToMediaDeviceName(name)]: true,
      });
      return {
        state: 'granted',
      };
    } catch {
      return {
        state: 'denied',
      };
    }
  }
  switch (userAgentInfo?.browser.name?.toLowerCase() as UAParserSupportedBrowserName) {
    case 'firefox': {
      try {
        const result = await navigator.mediaDevices.getUserMedia({
          video: name === ('camera' as PermissionName),
          audio: name === ('microphone' as PermissionName),
        });
        return {
          state: result.active ? 'granted' : 'denied',
        };
      } catch {
        return {
          state: 'denied',
        };
      }
    }
    default: {
      return navigator.permissions.query({ name });
    }
  }
}

export async function isPermissionDenied(name: 'camera' | 'microphone'): Promise<boolean> {
  const permissionName = name as PermissionName;
  if (isBrowser('firefox')) {
    try {
      const result = await navigator.mediaDevices.getUserMedia({
        video: name === 'camera',
        audio: name === 'microphone',
      });

      return !result.active;
    } catch {
      return true;
    }
  }

  try {
    if (!isPermissionsApiAvailable) {
      await navigator.mediaDevices.getUserMedia({
        [mapPermissionNameToMediaDeviceName(permissionName)]: true,
      });
      return true;
    }
    const result = await navigator.permissions.query({ name: permissionName });
    return result.state === 'denied';
  } catch {
    return false;
  }
}

export async function getDeviceInfo() {
  const devices = (await navigator.mediaDevices?.enumerateDevices()) ?? [];

  return {
    audioInputDevices: devices.filter(device => device.kind === 'audioinput'),
    videoInputDevices: devices.filter(device => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter(device => device.kind === 'audiooutput'),
    hasAudioInputDevices: devices.some(device => device.kind === 'audioinput'),
    hasVideoInputDevices: devices.some(device => device.kind === 'videoinput'),
  };
}

export async function getHardwarePermissionStates(): Promise<{
  cameraPermissionState: PermissionState;
  microphonePermissionState: PermissionState;
}> {
  const cameraPermissionState = (await getHardwarePermissionStatus('camera' as PermissionName)).state;
  const microphonePermissionState = (await getHardwarePermissionStatus('microphone' as PermissionName)).state;

  return {
    cameraPermissionState,
    microphonePermissionState,
  };
}

// Only supports windows and mac
export const currentOSName: 'mac' | 'windows' | null = (() => {
  if (userAgentInfo?.os.name === 'Mac OS') {
    return 'mac';
  }
  if (userAgentInfo?.os.name === 'Windows [Phone/Mobile]') {
    return 'windows';
  }
  return null;
})();
