'use client';

import { datadogRum } from '@datadog/browser-rum';

import { IdentifyUser } from './IdentifyUser';

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID!,
  clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN!,
  site: 'us3.datadoghq.com',
  service: 'sessions',
  env: 'production',
  version: process.env.NEXT_PUBLIC_SESSIONS_APP_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  enableExperimentalFeatures: ['feature_flags'],
  allowedTracingUrls: [
    { match: process.env.NEXT_PUBLIC_GRAPHQL_URL!, propagatorTypes: ['tracecontext'] },
    { match: process.env.NEXT_PUBLIC_DOCS_HTTP!, propagatorTypes: ['tracecontext'] },
  ],
});

export const DatadogInit = () => {
  return <IdentifyUser />;
};
