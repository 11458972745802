import { cacheExchange } from '@urql/exchange-graphcache';

import {
  CollectionsListDocument,
  CurrentSharesDocument,
  ShareStatus,
  TeamInsightsDocument,
  InsightViewsDocument,
  CollectionViewsDocument,
  ShareRequestStatus,
  ShareRequesterType,
} from '@/generated/graphql';

import type {
  CurrentSharesQuery,
  CurrentSharesQueryVariables,
  MutationShareByEmailsArgs,
  RevokeShareAccessMutation,
  ShareByDomainMutationVariables,
  TeamInsightsQuery,
  TeamInsightsQueryVariables,
  UpdateInsightMutationVariables,
  CollectionsListQuery,
  CollectionsListQueryVariables,
  CollectionQuery,
  InsightQuery,
  View,
  UpdateShareRequestMutation,
} from '@/generated/graphql';

export const urqlCacheExchange = cacheExchange({
  updates: {
    Mutation: {
      updateShareRequest(result: UpdateShareRequestMutation, args, cache) {
        const invalidateShareRequest = () => {
          cache.invalidate({ __typename: 'ShareRequest', _id: (args as any).input._id });
        };

        if (result.updateShareRequest?.status === ShareRequestStatus.Rejected) {
          invalidateShareRequest();
          return;
        }

        if (result.updateShareRequest?.status === ShareRequestStatus.Approved) {
          invalidateShareRequest();
          cache.updateQuery<CurrentSharesQuery, CurrentSharesQueryVariables>(
            {
              query: CurrentSharesDocument,
              variables: {
                first: 30,
                filter: {
                  status: {
                    in: [ShareStatus.Invited, ShareStatus.Opened],
                  },
                  type: {
                    eq: result.updateShareRequest.type,
                  },
                  type_id: {
                    eq: result.updateShareRequest.type_id,
                  },
                },
              },
            },
            data => {
              if (data?.shares.nodes && result.updateShareRequest?.share) {
                data.shares.nodes.push(result.updateShareRequest.share);
              }

              return data;
            },
          );
        }
      },
      shareByEmails(result, args, cache) {
        cache.updateQuery<CurrentSharesQuery, CurrentSharesQueryVariables>(
          {
            query: CurrentSharesDocument,
            variables: {
              first: 30,
              filter: {
                status: {
                  in: [ShareStatus.Invited, ShareStatus.Opened],
                },
                type: {
                  eq: (args as MutationShareByEmailsArgs)?.input?.type,
                },
                type_id: {
                  eq: (args as MutationShareByEmailsArgs)?.input?.type_id,
                },
              },
            },
          },
          data => {
            data!.shares!.nodes.push(...(result.shareByEmails as any));
            return data;
          },
        );
      },
      createShare(result, args, cache) {
        return cache
          .inspectFields('Query')
          .filter(field => field.fieldName === 'shares')
          .forEach(() => {
            cache.updateQuery<CurrentSharesQuery, CurrentSharesQueryVariables>(
              {
                query: CurrentSharesDocument,
                variables: {
                  first: 30,
                  filter: {
                    status: {
                      in: [ShareStatus.Invited, ShareStatus.Opened],
                    },
                    type: {
                      eq: (args as ShareByDomainMutationVariables).input?.type,
                    },
                    type_id: {
                      eq: (args as ShareByDomainMutationVariables).input?.type_id,
                    },
                    requester_type: {
                      ne: ShareRequesterType.Team,
                    },
                  },
                },
              },
              data => {
                data!.shares!.nodes!.push(result.createShare as any);
                return data;
              },
            );
          });
      },
      revokeShare(result, args, cache, _info) {
        return cache
          .inspectFields('Query')
          .filter(field => field.fieldName === 'shares')
          .forEach(() => {
            const typedResult = result as RevokeShareAccessMutation;
            cache.updateQuery<CurrentSharesQuery, CurrentSharesQueryVariables>(
              {
                query: CurrentSharesDocument,
                variables: {
                  first: 30,
                  filter: {
                    status: {
                      in: [ShareStatus.Invited, ShareStatus.Opened],
                    },
                    type: {
                      eq: typedResult?.revokeShare?.type,
                    },
                    type_id: {
                      eq: typedResult?.revokeShare?.type_id,
                    },
                  },
                },
              },
              data => {
                data!.shares!.nodes! = data?.shares?.nodes.filter(a => a?._id !== args.id)!;
                return data;
              },
            );
          });
      },
      updateInsight(_result, args, cache, _info) {
        const typedArgs = args as UpdateInsightMutationVariables;
        cache
          .inspectFields('Query')
          .filter((field: any) => field.fieldName === 'insights')
          .forEach(field => {
            cache.updateQuery<TeamInsightsQuery>(
              {
                query: TeamInsightsDocument,
                variables: field.arguments as TeamInsightsQueryVariables,
              },
              data => {
                const i = data?.insights.nodes?.findIndex(a => a?._id === typedArgs.input.id);

                if (i && data?.insights?.nodes?.[i]) {
                  data.insights.nodes[i] = {
                    ...data?.insights?.nodes?.[i]!,
                    title: typedArgs.input.title,
                  };
                }

                return data;
              },
            );
          });
      },
      createInsight(result, args, cache, _info) {
        cache
          .inspectFields('Query')
          .filter((field: any) => field.fieldName === 'insights')
          .forEach(field => {
            cache.updateQuery<TeamInsightsQuery>(
              {
                query: TeamInsightsDocument,
                variables: field.arguments as TeamInsightsQueryVariables,
              },
              data => {
                data?.insights?.nodes?.push(result.createInsight as any);

                data!.insights.totalCount += 1;

                return data;
              },
            );
          });
      },
      createCollection(result, args, cache, _info) {
        cache
          .inspectFields('Query')
          .filter((field: any) => field.fieldName === 'collections')
          .forEach(field => {
            cache.updateQuery<CollectionsListQuery>(
              {
                query: CollectionsListDocument,
                variables: field.arguments as CollectionsListQueryVariables,
              },
              data => {
                data?.collections?.nodes?.push(result.createCollection as any);

                return data;
              },
            );
          });
      },
      deleteCollection(result, args, cache, _info) {
        cache
          .inspectFields('Query')
          .filter((field: any) => field.fieldName === 'collections')
          .forEach(field => {
            cache.updateQuery<CollectionsListQuery>(
              {
                query: CollectionsListDocument,
                variables: field.arguments as CollectionsListQueryVariables,
              },
              data => {
                data!.collections!.nodes = data?.collections.nodes?.filter(r => r?._id?.toString() !== args._id);

                return data;
              },
            );
          });
      },
      updateInsightViews(result, args, cache, _info) {
        cache.updateQuery<InsightQuery>({ query: InsightViewsDocument, variables: args }, data => {
          if (result.updateInsightViews) {
            data!.insight!.views = result.updateInsightViews as View[];
          }
          return data;
        });
      },
      updateCollectionViews(result, args, cache, _info) {
        cache.updateQuery<CollectionQuery>({ query: CollectionViewsDocument, variables: args }, data => {
          if (result.updateCollectionViews) {
            data!.collection!.views = result.updateCollectionViews as View[];
          }
          return data;
        });
      },
    },
  },
});
