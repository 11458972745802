import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';

import { analytics } from './analytics';
import { useViewerContext } from './context/ViewerContext/ViewerContext';

export const IdentifyUser = () => {
  const [hasIdentified, setHasIdentified] = useState(false);
  const { details, status } = useViewerContext();

  useEffect(() => {
    if (!details || hasIdentified) {
      return;
    }

    datadogRum.setUser({
      id: details?._id,
      email: details?.email!,
    });

    analytics.identify({
      id: details._id,
      email: details.email!,
      name: details.displayName ?? undefined,
      teamId: details.ConnectedTeam?._id,
      teamName: details.ConnectedTeam?.name ?? undefined,
    });

    // DEBUG INFO
    datadogRum.setUserProperty('connected_team', details.ConnectedTeam?._id ?? 'none');
    datadogRum.setUserProperty('auth_status', status);

    setHasIdentified(true);
  }, [details]);

  return null;
};
