import { useKindeBrowserClient } from '@kinde-oss/kinde-auth-nextjs';
import { useState, useEffect } from 'react';

import { AUTH_CONFIG } from '@/src/network/auth';

import PageLoading from '../Loading/PageLoading';

import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const SetUpKinde = ({ children }: Props) => {
  const { getToken, isLoading, isAuthenticated } = useKindeBrowserClient();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      AUTH_CONFIG.getToken = getToken;
    }

    setReady(true);
  }, [isLoading]);

  if (!ready) {
    return <PageLoading />;
  }

  return children;
};
