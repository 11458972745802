import { authExchange } from '@urql/exchange-auth';
import { createClient as createWSClient } from 'graphql-ws';
import { StatusCodes } from 'http-status-codes';
import { createClient, dedupExchange, fetchExchange, subscriptionExchange } from 'urql';

import { TypedStorage } from '@/utils/TypedStorage';

import { AUTH_CONFIG } from './auth';
import { urqlCacheExchange } from './utils/cache-exchange';

import type { SubscribePayload } from 'graphql-ws';

function createSubscriptionClient() {
  const wsClient = createWSClient({
    url: process.env.NEXT_PUBLIC_GRAPHQL_WS_URL!,
  });

  return subscriptionExchange({
    forwardSubscription(operation) {
      return {
        subscribe: sink => {
          const dispose = wsClient.subscribe(operation as SubscribePayload, sink);
          return {
            unsubscribe: dispose,
          };
        },
      };
    },
  });
}

export const client = createClient({
  url: `${process.env.NEXT_PUBLIC_GRAPHQL_URL!}/graphql`,
  exchanges: [
    dedupExchange,
    urqlCacheExchange,
    // @ts-expect-error FIXME - type of _opaque doesn't match?
    authExchange(async utils => {
      let accessToken = AUTH_CONFIG.getToken();

      return {
        didAuthError: value => {
          return value.graphQLErrors.some(e => {
            return (
              e.extensions.code === StatusCodes.UNAUTHORIZED ||
              e.message === 'invalid token' ||
              e.message === 'jwt expired'
            );
          });
        },
        willAuthError: () => {
          return !accessToken;
        },
        refreshAuth: async () => {
          accessToken = AUTH_CONFIG.getToken();
        },
        addAuthToOperation: operation => {
          // If current context is from share context, we use that one.
          const shareToken = TypedStorage.get('share_token');
          return utils.appendHeaders(operation, {
            ...(accessToken ? { Authorization: `${accessToken}` } : {}),
            ...(shareToken ? { 'x-authorization-share': shareToken } : {}),
            'x-graphql-client-name': 'sessions',
            'x-graphql-client-version': process.env.npm_package_version ?? 'N/A',
          });
        },
      };
    }),
    fetchExchange,
    createSubscriptionClient(),
  ],
});
