'use client';

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@askable/ui/components/ui/dialog';
import { useFeatureFlags } from 'feature-flags';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { userAgentInfo } from '@/utils/device-utils';

import { AppStoreLogo } from '../whoops/components/appStoreLogo';
import { PlayStoreLogo } from '../whoops/components/playStoreLogo';

const ANDROID_STORE_LINK = `https://play.google.com/store/apps/details?id=${process.env.NEXT_PUBLIC_PLAY_STORE_ID}`;
const APPLE_STORE_LINK = `https://apps.apple.com/au/app/id${process.env.NEXT_PUBLIC_APP_STORE_ID}`;

type Device = 'ios' | 'android' | 'unknown';

export const MobileError = () => {
  const { SESSIONS_MOBILE } = useFeatureFlags(['SESSIONS_MOBILE']);
  const { t } = useTranslation();

  const device = match<string, Device>(userAgentInfo?.os.name ?? '')
    .with('iOS', () => 'ios')
    .with('Android', () => 'android')
    .otherwise(() => 'unknown');

  const data = match<[string, boolean], { title: string; content: React.FC }>([device, SESSIONS_MOBILE])
    .with(['ios', true], () => ({
      title: t('errors.app.mobileAppAvailable'),
      content: AppStoreButton,
    }))
    .with(['android', true], () => ({
      title: t('errors.app.mobileAppAvailable'),
      content: PlayStoreButton,
    }))
    .with(['ios', false], () => ({
      title: t('errors.app.mobileNotSupported'),
      content: AppStoreButton,
    }))
    .with(['android', false], () => ({
      title: t('errors.app.mobileNotSupported'),
      content: PlayStoreButton,
    }))
    .otherwise(() => ({ title: t('errors.app.sessionMobileOnly'), content: WebContent }));

  return (
    <Dialog modal open>
      <DialogContent hideCloseButton className="border-border sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-balance text-center text-base">{data.title}</DialogTitle>
        </DialogHeader>
        <data.content />
      </DialogContent>
    </Dialog>
  );
};

const AppStoreButton = () => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className="w-32"
      onClick={() => window.location.assign(APPLE_STORE_LINK)}
      aria-label={t('mobile.openInAppStore')}
    >
      <AppStoreLogo />
    </button>
  );
};

const PlayStoreButton = () => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className="w-32"
      onClick={() => window.location.assign(ANDROID_STORE_LINK)}
      aria-label={t('mobile.openInPlayStore')}
    >
      <PlayStoreLogo />
    </button>
  );
};

const WebContent = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center gap-4">
      <p className="text-text-secondary text-center text-sm">{t('errors.app.scanQRCode')}</p>
      <QRCodeSVG value={`${process.env.NEXT_PUBLIC_PARTICIPANT_URL}/mobile`} size={200} marginSize={4} />
    </div>
  );
};
